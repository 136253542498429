@font-face {
  font-family: "Aesthetic Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Aesthetic Regular"),
    url("./assets/fonts/Aesthetic-Font/misc/Aesthetic-Regular-ed9f.woff")
      format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("woff");
  font-display: swap;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
html,
body {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font: 19px / 23px Montserrat, "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* Media Query */
@media (max-width: 1380px) {
  html,
  body {
    font-size: 1rem;
    line-height: normal;
  }
}
